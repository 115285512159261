export default [{
        path: "/",
        name: "index",
        meta: {
            title: "Anasayfa"
        },
        component: () => import("../views/course-course-view.vue"),
    },
    {
        path: "/admins/course-course-add",
        name: "course-course-add",
        meta: {
            title: "Kurs Ekle"
        },
        component: () => import("../views/course-course-add.vue"),
    },
    {
        path: "/admins/course-course-edit/:courseId",
        name: "course-course-edit",
        meta: {
            title: "Kurs Düzenle"
        },
        component: () => import("../views/course-course-edit.vue"),
    },
    {
        path: "/admins/course-course-view",
        name: "course-course-view",
        meta: {
            title: "Kurs Listesi"
        },
        component: () => import("../views/course-course-view.vue"),
    },
    {
        path: "/admins/course-student-add",
        name: "course-student-add",
        meta: {
            title: "Öğrenci Ekle"
        },
        component: () => import("../views/course-student-add.vue"),
    },
    {
        path: "/admins/course-student-edit/:studentId",
        name: "course-student-edit",
        meta: {
            title: "Öğrenci Düzenle"
        },
        component: () => import("../views/course-student-edit.vue"),
    },
    {
        path: "/admins/applied-student-list/:courseId",
        name: "applied-student-list",
        meta: {
            title: "Başvuran Öğrenci Listesi"
        },
        component: () => import("../views/applied-student-list.vue"),
    },
    {
        path: "/admins/course-teacher-edit/:teacherId",
        name: "course-teacher-edit",
        meta: {
            title: "Öğretmen Düzenle"
        },
        component: () => import("../views/course-teacher-edit.vue"),
    },
    {
        path: "/admins/course-student-list",
        name: "course-student-list",
        meta: {
            title: "Öğrenci Listesi"
        },
        component: () => import("../views/course-student-list.vue"),
    },
    {
        path: "/admins/course-teacher-add",
        name: "course-teacher-add",
        meta: {
            title: "Öğretmen Ekle"
        },
        component: () => import("../views/course-teacher-add.vue"),
    },
    {
        path: "/admins/course-teacher-list",
        name: "course-teacher-list",
        meta: {
            title: "Öğretmen Listesi"
        },
        component: () => import("../views/course-teacher-list.vue"),
    },
    {
        path: "/contact-us",
        name: "contact-us",
        meta: {
            title: "İletişim"
        },
        component: () => import("../views/contact-us.vue"),
    },
    {
        path: "/course-details/:courseId",
        name: "Course Details",
        meta: {
            title: "Kurs Detayı"
        },
        component: () => import("../views/course-details.vue"),
    },
    {
        path: "/checkout",
        name: "checkout",
        meta: {
            title: "Sepetim"
        },
        component: () => import("../views/checkout.vue"),
    },
    {
        path: "/login-v1",
        name: "login-v1",
        meta: {
            title: "Giriş Yap",
            noAuth: true
        },
        component: () => import("../views/login.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        meta: {
            title: "Profil"
        },
        component: () => import("../views/account-profile.vue"),
    },
    {
        path: "/report",
        name: "report",
        meta: {
            title: "Döküm"
        },
        component: () => import("../views/course-report.vue"),
    },

]