// src/utils.js

// const host = "http://localhost:3031/backend";
const host = "https://netskurs.com/backend";
export const getFullImageUrl = (imagePath) => {
    return `${host}${imagePath}`;
};

export const getHost = () => {
    return host;
};