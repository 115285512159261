    import PhosphorIcons from "@phosphor-icons/vue";
    import BootstrapVueNext from 'bootstrap-vue-next';
    import Wizard from 'form-wizard-vue3';
    import {
        createApp
    } from 'vue';
    import VueApexCharts from "vue3-apexcharts";
    import App from './App.vue';
    import router from './router';
    import store from "./state/store";
    // import CoolLightBox from 'vue-cool-lightbox';

    // Packages CSS import
    import '@vueform/slider/themes/default.css';
    import {
        createHead
    } from '@vueuse/head';
    import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
    import 'bootstrap/dist/css/bootstrap.css';
    import 'form-wizard-vue3/dist/form-wizard-vue3.css';
    import 'simplebar-vue/dist/simplebar.min.css';

    import '@/assets/scss/style.scss';
    // bootstrap.bundle.js
    import 'bootstrap/dist/js/bootstrap.bundle.js';
    const head = createHead();
    createApp(App)
        .use(store)
        .use(router)
        .use(BootstrapVueNext)
        .use(VueApexCharts)
        .use(PhosphorIcons).use(head)
        // .use(CoolLightBox)
        .component('Wizard', Wizard)
        .mount('#app')