<script>
import { useHead } from '@vueuse/head';

export default {
  name: 'App',
  setup() {
    useHead({
      title: 'NetsKurs Kurs Kayıt Platformu',
      meta: [
        { property: 'og:image', content: 'https://netskurs.com/img/logo_yatay_og.png' },
        { property: 'og:site_name', content: 'NetsKurs Kurs Kayıt Platformu' },
        { property: 'og:title', content: 'NetsKurs Kurs Kayıt Platformu' },
        { property: 'og:description', content: 'Dilediğin kurslara anında kayıt ol!' },
        { property: 'og:image', itemprop: 'image', content: 'https://netskurs.com/img/logo_yatay_og.png' },
        { property: 'og:type', content: 'website' },
        { property: 'og:updated_time', content: '1725310307' },
      ],
    });
  },
};
</script>

<template>
  <router-view></router-view>
</template>
