import {
    createStore
} from 'vuex';

import {
    getHost
} from '@/utils';
import axios from 'axios';
import modules from './modules';

const store = createStore({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
    state: {
        isSidebarHidden: false,
        isMobileSidebarActive: false,
        isFixedWidth: false,
        shoppingCart: [],
        selectedCategory: '',
    },
    mutations: {
        toggleSidebar(state) {
            state.isSidebarHidden = !state.isSidebarHidden;
        },
        toggleMobileSidebar(state) {
            state.isMobileSidebarActive = !state.isMobileSidebarActive;
        },
        setFullWidth(state) {
            state.isFixedWidth = false;
        },
        setFixedWidth(state) {
            state.isFixedWidth = true;
        },
        changeLayoutType(state, payload) {
            state.layoutType = payload.layoutType;
        },
        setShoppingCart(state, cart) {
            state.shoppingCart = cart;
        },
        setSelectedCategory(state, category) {
            state.selectedCategory = category;
        },
    },
    actions: {
        async fetchShoppingCart({
            commit
        }) {
            try {
                const response = await axios.get(getHost() + '/api/shopping-cart', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                commit('setShoppingCart', response.data);
            } catch (error) {
                console.error('Failed to fetch shopping cart:', error);
            }
        },
    },
    getters: {
        isFixedWidth: state => state.isFixedWidth,
        shoppingCart: state => state.shoppingCart,
        selectedCategory: state => state.selectedCategory,
    },
});

export default store;